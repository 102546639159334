import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch, HashRouter, withRouter } from "react-router-dom";

import configureStore, { history } from "./store";
import App from "./containers/App";
import { APP_NAME, PANEL_NAME } from "./ApiUrl";

export const store = configureStore();

class MainApp extends React.Component {
  componentDidUpdate() {
    let hash = this.props.location.hash;
    let title = "";
    switch (true) {
      case hash.includes("dashboard"):
        title = "Dashboard";
        break;
      case hash.includes("customers"):
        title = "Customers";
        break;
      case hash.includes("driver"):
        title = "Drivers";
        break;
      case hash.includes("bike-types"):
        title = "Car Types";
        break;
      case hash.includes("trips"):
        title = "Trips";
        break;
      case hash.includes("promo-codes"):
        title = "Promo codes";
        break;
      case hash.includes("contentPages"):
        title = "Content Pages";
        break;
      case hash.includes("faq"):
        title = "FAQ";
        break;
      case hash.includes("notification"):
        title = "Push Notification";
        break;
      case hash.includes("users"):
        title = "Admin Settings";
        break;
      case hash.includes("settings"):
        title = "Settings";
        break;
      default:
        title = "Admin";
        break;
    }
    document.title = `${APP_NAME} | ${title}`;
  }
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            <HashRouter>
              <Route path="/" component={App} />
            </HashRouter>
          </Switch>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default withRouter(MainApp);
